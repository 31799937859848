$(document).ready(function(){

  $(".main").onepage_scroll({
    sectionContainer: "section",
    responsiveFallback: 500,
    loop: true
  });

  var client_w=document.body.clientWidth;
  var client_h=document.body.clientHeight;
  if ((client_h < 450) && (client_w > 500) && (client_w < 750)) {
    $("body").addClass("disabled-onepage-scroll");
  }

  $('.go_to').click( function(){
    var scroll_el = $(this).attr('href');
    if ($(scroll_el).length != 0) {
      $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 500);
    }
    return false;
  });
});

$(window).resize(function() {
  var client_w=document.body.clientWidth;
  var client_h=document.body.clientHeight;
  if ((client_h < 450) && (client_w > 500) && (client_w < 750)) {
    location.reload();
    $("body").addClass("disabled-onepage-scroll");
  }
});

$(function() {
  $("#modal-1").on("change", function() {
    if ($(this).is(":checked")) {
      $("body").addClass("modal-open");
    } else {
      $("body").removeClass("modal-open");
    }
  });

  $(".modal-fade-screen, .modal-close").on("click", function() {
    $(".modal-state:checked").prop("checked", false).change();
  });

  $(".modal-inner").on("click", function(e) {
    e.stopPropagation();
  });
});
